<template>
  <div class="index-container">
    <customerFilter
      ref="customerFilter"
      :filter="filter"
      :queryType="queryType"
    >
      <el-button type="primary" @click="handleQuery()">查询</el-button>
    </customerFilter>
    <div class="tabs-container">
      <el-tabs v-model="queryType" @tab-click="typeChange">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.key"
          :label="tab.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="tab-content">
      <heartList
        ref="heartList"
        :filter="filter"
        v-show="queryType == 'Heart'"
      />
      <ikdcList ref="ikdcList" :filter="filter" v-show="queryType == 'IKDC'" />
      <vasList ref="vasList" :filter="filter" v-show="queryType == 'VAS'" />
    </div>
  </div>
</template>
  <script>
import heartList from "./components/heartList.vue";
import ikdcList from "./components/ikdcList.vue";
import vasList from "./components/vasList.vue";
import customerFilter from "./components/customerFilter.vue";
export default {
  components: { customerFilter, heartList, ikdcList, vasList },
  data() {
    return {
      tabs: [],
      filter: "",
      queryType: "Heart",
    };
  },
  methods: {
    handleQuery() {
      let tempfilter = this.$refs.customerFilter.getfilter();
      tempfilter.queryType = this.queryType;
      this.filter = tempfilter;
    },
    typeChange(v) {
      debugger;
      console.log("type changed");
      this.handleQuery();
    },
  },
  mounted() {
    this.tabs = [
      { key: "Heart", name: "心血管风险筛查", value: 0 },
      { key: "IKDC", name: "IKDC自测", value: 1 },
      { key: "VAS", name: "VAS自测", value: 2 },
    ];
    this.handleQuery();
  },
};
</script>
  <style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>